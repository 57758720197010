.app-content-modal-footer {
    display: flex;
}

.modal.fade.show {
    background: #eee2;
    align-items: center;
}

.modal .hrv-modal .modal-content {
    background: var(--hrv-back-color);
    padding: 0.5rem;
}

.modal .hrv-modal .modal-content .hrv-modal-header,
.modal .hrv-modal .modal-content .hrv-modal-body,
.modal .hrv-modal .modal-content .hrv-modal-footer {
    background: var(--hrv-back-color-active);
}