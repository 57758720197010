.permission-screen {
    padding: 0.3rem;
    width: 99%;
    max-width: unset;
}

.permission-screen .listview-content {
    margin-top: 1vh;
}

.permission-screen .header-permission {
    display: flex;
    padding-right: 0.3vw;
    justify-content: space-between;
}

.permission-item {
    width: 100%;
    color: rgb(87, 106, 224);
    font-size: 0.9rem;
}

.permission-screen .item-role {
    width: 100%;
    margin-left: 1vw;
}

.permission-screen .hrv-table tbody td:first-child {
    padding: 0.8rem 1rem ;
}

.permission-screen .item-role:hover {
    background-color: rgb(203, 211, 211);
}