.theme-detail {
  width: 80vw;
}
.theme-detail > * {
  width: 100%;
  margin-top: 1rem;
  background: var(--hrv-back-color-active);
  border-radius: 0.4rem;
}

.theme-name > span + div {
  margin-top: 5px;
}
.theme-description > span + div {
  margin-top: 5px;
}
.theme-site-demo-url > span + div {
  margin-top: 5px;
}
.theme-default-categories > span + div {
  margin-top: 5px;
}
.theme-code > span + div {
  margin-top: 5px;
}
.theme-info {
  padding: 1% 2% 1.5% 2%;
  background: var(--hrv-translucent-black);
  border-radius: 0.5rem;
  color: white;
  max-width: 70%;
  max-height: 100%;
  overflow: hidden;
}
.theme-info * {
  background: transparent;
  font-size: calc(9px + 0.9vw);
}

.theme-status {
  width: 100%;
  position: relative;
  padding: 0;
}
.theme-detail .theme-status{
  text-align: left !important;
}

.theme-status .status-selector {
  position: absolute;
  top: calc(0.3rem + 0vw);
  right: calc(0.3rem + 0.4vw);
}

.theme-html {
  padding: 2%;
  background: var(--hrv-back-color-active);
  max-height: 80vh;
  overflow: auto;
  font-family: initial;
  font-size: initial;
}

.theme-image-labels {
  display: flex;
}
.theme-image-labels > * {
  flex: 1;
}
.theme-image-labels > * + * {
  margin-left: calc(0.4rem + 0.4vw);
}

.theme-image-banner {
  display: flex;
  align-items: stretch;
  margin-top: calc(0.3rem + 0.3vw);
}
.theme-image-banner > * {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(0.4rem + 0.4vw);
  border: 1px solid var(--hrv-back-color-edge-light);
  border-radius: 0.3rem;
  background: #f0f5fa;
}
.theme-image-banner > * + * {
  margin-left: calc(0.4rem + 0.4vw);
}
.theme-image-banner > * * {
  max-height: 100%;
  max-width: 100%;
}

.theme-type-trial {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.theme-file-border {
  border: 1px solid #abcd;
  border-radius: 0.5rem;
  background: var(--hrv-back-color-active);
  padding: var(--hrv_distance_md);
}

.theme-file-version-header,
.theme-file-version {
  width: calc(5rem + 3vw);
  text-align: center;
}

.theme-file-desc-header,
.theme-file-desc {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme-file-status-header,
.theme-file-status {
  width: calc(7rem + 3vw);
}
.theme-file-status-header{
  text-align: center;
}
.theme-file-date-header,
.theme-file-date {
  width: calc(9rem + 3vw);
}

.theme-detail .app-partner{
  margin-top: 3vh;
}
.theme-detail .card-info > * span{
  color: var(--hrv-fore-color);
  font-size: 15px;
}
.theme-detail .link{
  margin-top: 0.7vh;
}

.theme-detail .theme-content{
  background: #ffffff;
  border: 1px solid var(--hrv-back-color-edge-light);
  padding: 10px;
  border-radius: 10px;
}
.theme-detail .theme-content-price{
  background: #ffffff;
  border: 1px solid var(--hrv-back-color-edge-light);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 3vh;
}
.theme-detail .theme-content-price .theme-list-price{
  margin-bottom: 2vh;
}
.theme-detail .theme-rejection-reason{
  margin-top: 3vh;
  margin-bottom: -3vh;
}
.theme-detail .card-info > *{
  padding: 10px 10px 5px 12px;
}