.rct-icons-fa5 *::before {
  content: '' !important;
}

.rct-icons-fa5 .rct-icon {
  width: 0;
}
.rct-node-icon {
  padding: 0;
}

span.rct-checkbox {
  border-radius: 3px;
  border: 1px solid silver;
  width: calc(14px + 0.2vw);
  height: calc(14px + 0.2vw);
  display: inline-flex;
  align-items: center;
  padding: 2px !important;
  background: #fff0;
}

.rct-checkbox * {
  color: white;
}

.rct-checkbox:hover {
  background: white;
}

input:checked + .rct-checkbox {
  background: var(--hrv-blue);
}

.rct-text label {
  display: flex;
  align-items: center;
  padding: var(--hrv_distance_tn);
}

.rct-text:hover {
  background: none;
}

.react-checkbox-tree label {
  color: var(--hrv-fore-color-light);
}
.react-checkbox-tree label:hover {
  background: none;
  color: var(--hrv-fore-color);
}

.rct-collapse * {
  width: calc(9px + 0.2vw) !important;
}

.rct-title {
  line-height: normal;
}
