.header-table {
    display: flex;
    margin-bottom: 10px;
}

.filter-bar {
    padding-right: 0;
    flex: 10%;
}

.search-bar {
    padding-left: 0;
    flex: 90%;
}

.export-bar {
    flex: 5%;
    padding-left: 20px;
}

.search-bar .hrv-next-input {
    padding: 11px 12px;
}

.filter-bar .hrv-btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
}

.search-bar .hrv-next-input--stylized {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
}

.export-bar button {
    float: right;
}

.tag-filter {
    margin: 0px 0px 10px;
}

.modal_export_button {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.modal_export_button a {
    text-align: center;
}

.hrv-modal-header {
    border-bottom: 1px solid #9ca7b359;
}

.hrv-modal-footer {
    border-top: 1px solid #9ca7b359;
}

.modal_export {
    font-size: 13px;
}

.modal_export .hrv-modal-title {
    font-size: 16px !important;
}

.modal-dialog {
    height: 100vh !important;
    display: flex;
}

.modal-content {
    margin: auto !important;
    height: fit-content !important;
}
.text-search-wrapper-none-export .hrv-next-input--stylized
{
    border-radius: 4px!important;
    border: 1px solid #e0e0e0!important;
}