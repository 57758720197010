.tab-item-wrapper {
  display: flex;
  margin-bottom: -0.1rem;
}

.tab-content-wrapper {
  padding: 0.5rem;
  border: 1px solid var(--hrv-lighter-white-blue);
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  background: var(--hrv-back-color-active);
}

.tab-content {
  border: 1px;
  border-radius: 0.5rem;
  background: var(--hrv-light-white);
  overflow: auto;
}

.tab-content .hrv-collapse {
  background: transparent;
}

.tab-content .hrv-collapse-item {
  width: 100%;
}

.tab-content .hrv-collapse-header {
  border: 1px solid var(--hrv-back-color-edge);
  border-radius: 0.3rem;
}

.tab-content .hrv-collapse-item-active .hrv-collapse-header {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-content .hrv-collapse-content {
  border: 1px solid var(--hrv-back-color-edge);
  border-radius: 0.3rem;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.hrv-collapse>.hrv-collapse-item {
  border: none;
  margin-top: 1vh;
}