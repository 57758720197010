.hrv-popover * {
  background: white;
  /* width: min-content;
  height: min-content; */
}

.hrv-popover .popover-content-wrapper {
  background: white;
  transform-origin: 197.531px -4px;
  padding: var(--hrv_distance_md);
  box-shadow: 0px 5px 10px #21212133;
}

.hrv-popover-hidden {
  display: block;
  background: transparent;
}

.hrv-popover-inner {
  box-shadow: none;
}

.hrv-popover-hidden .hrv-popover-inner-content {
  width: 0;
  height: 0;
}

.zoom-big-enter {
  animation-duration: .2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.zoom-big-leave {
  animation-duration: .2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

.zoom-big-leave.zoom-big-leave-active { }

@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
