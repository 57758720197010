* {
  scrollbar-color: #569a #78ca;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 7px;
  /* horizontal scrollbar width */
  height: 7px;
  /* vertical scrollbar height */
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dee2e6;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #b1b3c1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a7a8b1;
}

p:nth-last-child(1) {
  margin-bottom: 0;
}

input[readonly] {
  color: #738393;
  background-color: #f9fafb;
}

input:focus {
  outline: 1px solid #88aad5;
  border-radius: 3px;
}

.label-input-group {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9e9e9e;
  margin: 0 0 8px;
}

.form-group.flex-1-1+.form-group.flex-1-1 {
  margin-left: 15px;
}

.hrv-next-input-radio+.hrv-next-input-radio {
  margin-top: 10px;
}

.hrv-form-group {
  margin-bottom: 20px;
}

.hrv-form-label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

/* Header Page */

.ui-section-page {
  margin-top: 20px;
}

.ui-section-head {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ui-section-title {
  font-size: 18px;
  line-height: 36px;
  color: #474747;
  font-weight: bold;
  margin-right: 10px;
}

.ui-next-section {
  padding: 20px;
}

.ui-next-section+.ui-next-section {
  padding: 0 20px 20px;
  margin-top: 20px;
}

.ui-heading {
  font-size: 1.6rem;
  font-weight: 600;
}

.ui-subheading {
  color: #637381;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.ui-important-value {
  font-size: 2.6rem;
  line-height: 3.2rem;
  font-weight: 400;
  margin: 1rem 0rem 0rem 0rem;
}

.flex-wrap .card+.card {
  margin-left: 20px;
}

.card-body {
  padding: 2%;
  border-radius: 4px;
}

.card {
  padding: calc(1rem + 0.5vw);
  margin: calc(0.2rem + 0.5vw) 0;
}

.ui-card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.flex .ui-card+.ui-card {
  margin-left: 2rem;
}

.ui-card-header {
  display: block;
  padding: 2rem 2rem 0;
}

.ui-card-section {
  padding: 2rem;
}

.ui-card-section+.ui-card-section {
  margin-top: 20px;
}

.ui-stack {
  display: -webkit-flex;
  display: flex;
}

.ui-stack-item-fill {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

.ui-form-group {
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wrapper-content {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #dddfe2;
}

.ui-card .wrapper-content {
  border: none;
}

.ui-annotated-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 15px;
}

.ui-annotated-section-container {
  margin: 15px 0;
}

.ui-annotated-section-container+.ui-annotated-section-container .ui-annotated-section {
  margin-top: 0;
  padding-top: 1.6rem;
  border-top: 1px solid #dfe3e8;
}

.ui-annotated-section--title {
  color: #212b36;
  padding: 1.6rem 0;
}

.ui-annotated-section--title h2 {
  font-size: 16px;
  font-weight: 600;
}

.ui-annotated-section--description {
  color: #637381;
  padding: 0;
  line-height: 1.4;
  padding-right: 10px;
}

.ui-annotated-section--content,
.ui-annotated-section--content {
  -webkit-box-flex: 3;
  -webkit-flex: 3 1;
  -ms-flex: 3 1;
  flex: 3 1;
  max-width: 100%;
  min-width: 0;
  padding: 0;
}

.ui-section-content {
  padding: 20px;
}

.ui-annotated-section--annotation,
.ui-annotated-section--annotation {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
}

.ui-annotated-section--title {
  padding: 1.6rem 0;
}

.ui-annotated-section--description {
  color: #637381;
  padding: 0 0 1.6rem;
}

.ui-annotated-section--description p {
  margin-bottom: 1.2rem;
}

.ui-footer-help {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: auto;
  margin: 2rem 0.8rem;
  text-align: center;
}

.ui-footer-help--content {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  padding: 1.6rem 2rem 1.6rem 1.6rem;
  border: 1px solid #dfe3e8;
  border-radius: 999px;
  color: #212b36;
  text-align: left;
}

.ui-footer-help--icon {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-right: 0.4rem;
  padding: 0.8rem;
  color: #95a7b7;
  font-size: 1.4rem;
  line-height: 2rem;
}

.ui-footer-help--icon::before {
  content: "";
  position: absolute;
  right: 0.4rem;
  height: 3.2rem;
  width: 3.2rem;
  background: white;
  border-radius: 50%;
}

.ui-footer-help--icon svg {
  fill: #47c1bf;
  color: white;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  z-index: 1;
}

.hrv-page {
  border-bottom: 1px solid #dfe3e8;
  padding-bottom: 15px;
  margin-top: 20px;
}

.hrv-page-header--maincontent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}

.hrv-page-header--title {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
}

.display-block {
  display: block;
}
.display-none {
  display: none;
}


.hrv-stack {
  display: -ms-flexbox;
  display: flex;
  margin-top: -16px;
  margin-left: -16px;
  flex-wrap: wrap;
}

.hrv-stack--item {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  margin-top: 5px;
  margin-left: 16px;
}

.hrv-badges {
  padding: calc(3px + 0.1vw) calc(4px + 0.1vw) !important;
  border-radius: 4px !important;
  min-width: fit-content;
}

.hrv-badges--status-primary-string {
  background: #CCE2FE;
  border: 1px solid #36A3F7;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 0.3vh;
}

.hrv-badges--status-error-string {
  background: #FFDDDD;
  border: 1px solid #FC625D;
  border-radius: 3px;
}

.hrv-badges--status-secondary-string {
  background-color: #D2D2D2;
  color: #212121 !important;
}

.hrv-badges--status-success-string {
  background: #CCF3E8;
  border: 1px solid #22C993;
  border-radius: 3px;
  box-sizing: border-box;
}

.hrv-badges--status-warning-string {
  background: #FFF0DA;
  border: 1px solid #FFB72D;
  border-radius: 3px;
}

.hrv-alert-secondary .hrv-alert-icon {
  background: rgba(167, 163, 156, 0.2);
}

.hrv-badges .hrv-badges-body {
  font-size: calc(10px + 0.15vw);
  line-height: normal;
  font-weight: 600;
  background: transparent;
}

.row-footer {
  border-top: 1px solid #e0e0e0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 15px;
}

.min-w-50px {
  min-width: 50px !important;
}

.ui-section-content-header {
  border-bottom: 1px solid #e0e0e0;
}

.hrv-table-container-empty {
  padding: 20px;
  text-align: center;
}

.ui-wrapper-page {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.txt-center {
  text-align: center;
}

.ui-section-item+.ui-section-item {
  margin-top: 20px;
}

.button {
  width: fit-content;
}

.button a {
  color: inherit;
}

.button svg {
  fill: #637381;
}

.hrv-btn {
  min-width: fit-content;
  min-height: 35px;
}


*+.btn,
*+.hrv-btn {
  margin-left: 20px;
}

*[style*="display: none"]+.btn,
*[style*="display: none"]+.hrv-btn {
  margin-left: 0px;
}

.hrv-btn.hrv-btn-primary,
.btn-primary {
  /* cursor: pointer; */
  /* background: linear-gradient(to bottom, var(--hrv-dark-blue), var(--hrv-darker-blue)); */
  /* display: flex; */
  /* border: 1px solid var(--hrv-dark-blue); */
  color: var(--hrv-back-color-active);
  font-weight: 400;
}

.hrv-btn.hrv-btn-primary:hover {
  /* background: linear-gradient(to bottom, var(--hrv-blue), var(--hrv-dark-blue)); */
  background-color: #135cda;
  color: white !important;
}

/* .hrv-btn.hrv-btn-primary:active {
  background: var(--hrv-dark-blue);
  border-color: var(--hrv-blue);
  box-shadow: inset 0 1px 0 0 var(--hrv-darker-blue), 0 0 0 1px var(--hrv-darker-blue);
  border: none;
  box-shadow: none;
} */

.hrv-btn.hrv-btn-primary[disabled] {
  background: var(--hrv-disabled-blue);
  cursor: not-allowed;
  border: 1px solid var(--hrv-lightest-blue);
}

.hrv-btn.hrv-btn-primary[disabled]:active {
  box-shadow: none;
}

.hrv-btn.hrv-btn-primary a,
.hrv-btn.hrv-btn-primary a:hover {
  color: white;
}

.hrv-btn.hrv-btn-primary.hrv-btn-loading,
.hrv-btn.hrv-btn-primary.hrv-btn-loading:hover {
  color: transparent !important;
  box-shadow: none;
}

.hrv-btn.hrv-btn-default {
  cursor: pointer;
  background: var(--hrv-white);
  -webkit-box-shadow: inset 0 1px 0 0 var(--hrv-lighter-blue);
  box-shadow: inset 0 1px 0 0 var(--hrv-translucent-white);
  border: 1px solid var(--hrv-darker-white);
  border-radius: 3px;
  color: var(--hrv-fore-color);
}

.hrv-btn.hrv-btn-default[disabled],
.hrv-btn.hrv-btn-default[disabled]:hover,
.hrv-btn.hrv-btn-default[disabled]:hover:active {
  background: var(--hrv-disabled-white);
  color: var(--hrv-fore-color-trans);
  border: 1px solid var(--hrv-darker-white);
  box-shadow: none;
}

.hrv-btn.hrv-btn-default:hover {
  background: var(--hrv-dark-white);
}

.hrv-btn.hrv-btn-danger {
  background: linear-gradient(to bottom, var(--hrv-red), var(--hrv-dark-red));
  color: var(--hrv-back-color-active);
}

.hrv-btn.hrv-btn-danger:hover {
  background: var(--hrv-darker-red);
}

.hrv-btn.hrv-btn-danger[disabled] {
  background: var(--hrv-disabled-red);
}

.hrv-btn * {
  background: transparent;
}

.hrv-popover,
.hrv-popover * {
  border-radius: var(--hrv_distance_xs);
}

.hrv-popover-title {
  padding: 14px;
}

.hrv-notification li {
  list-style-type: disc;
}

.code-box {
  border: 1px solid #ebedf0;
  border-radius: 3px;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 15px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.code-box .code-box-meta {
  position: relative;
  padding: 20px 35px 20px 20px;
  border-radius: 0 0 3px 3px;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}

.code-box .code-box-title {
  position: absolute;
  top: -10px;
  padding: 1px 8px;
  margin-left: -8px;
  color: #314659;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px 3px 0 0;
  background: #fff;
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.hrv-breadcrumb .hrv-breadcrumb-item {
  padding: 0;
  margin-top: 5px;
}

.hrv-breadcrumb .hrv-breadcrumb-item a {
  color: #7790b6;
  font-size: 14px Roboto-normal;
  line-height: 18px;
}

.hrv-breadcrumb .hrv-breadcrumb-item a:hover {
  color: var(--hrv-link-hover-color);
}

.hrv-breadcrumb svg {
  width: 22px;
  height: 22px;
}

.contentEditor {
  padding-right: 0px !important;
}

.contentEditor #cke-editor1 {
  min-height: 250px;
}

.hrv-tooltip-inner {
  color: var(--hrv-white);
  background-color: #443f3f;
}

a.hide-url {
  cursor: pointer;
}

.hrv-uploadfile--container {
  height: 100%;
}

.hrv-loading-main {
  border-color: var(--hrv-light-blue);
  border-right-color: transparent;
  border-width: 3px;
  animation: spin 1s linear infinite;
}

.hrv-loading-thumb>.hrv-loading-main {
  width: calc(2.5rem + 1vw);
  height: calc(2.5rem + 1vw);
  margin-top: calc(-0.7rem - 1vw);
}

.hrv-loading-icon>.hrv-loading-main {
  width: calc(1.8rem + 1vw);
  height: calc(1.8rem + 1vw);
  margin-top: calc(-0.3rem - 1vw);
}

.hrv-loading-icon {
  height: 100%;
  min-width: 10rem;
}

.hrv-select-selection {
  padding: 0.1rem;
  background: #f6f5f4;
}

.hrv-select-selection__rendered {
  /* margin: 0; */
  padding: 0.4rem 0.5rem;
  min-width: 9rem;
}

.hrv-select-dropdown div {
  padding: 0.2rem;
}

.hrv-select-dropdown-menu {
  padding: 0.2rem;
  padding-left: 0;
}

.hrv-select-selection:hover {
  border-color: var(--hrv-blue);
}

.hrv-select-selection-selected-value {
  overflow: visible;
}

.hrv-select-dropdown-menu-item,
.hrv-select-dropdown-menu-item-selected {
  border-radius: 0.3rem !important;
  padding: 0.5rem 0.3rem;
}

.hrv-select-dropdown-menu-item:hover,
.hrv-select-dropdown-menu-item-active {
  background: var(--hrv-dark-blue);
  color: var(--hrv-lightest-gray);
}

.hrv-select-arrow {
  margin-left: 0.2rem;
  margin-right: -0.3rem;
}

.hrv-popover-inner-content {
  color: inherit;
}

.hrv-popover-hidden .hrv-dropdown-menu,
.hrv-popover-hidden .header-modal {
  display: none;
}

.hrv-select {
  color: inherit;
}

.hrv-collapse>.hrv-collapse-item>.hrv-collapse-header .hrv-collapse-arrow {
  left: 1rem;
}

.hrv-collapse>.hrv-collapse-item>.hrv-collapse-header {
  padding: 1rem 1rem 1rem 2.5rem;
}

.hrv-collapse-content>.hrv-collapse-content-box {
  padding: .7rem;
}

.stretch .hrv-next-input--stylized {
  height: 100%;
}

.hrv-next-input {
  font-size: inherit;
  line-height: normal;
  padding: var(--hrv_distance_sm);
}

.hrv-next-input:focus,
.hrv-next-input--is-focused {
  border-color: var(--hrv-lightest-blue);
}

.hrv-next-input-checkbox .hrv-next-checkbox {
  opacity: 0.2;
}

.hrv-next-input-checkbox .hrv-next-checkbox--styled {
  background: var(--hrv-back-color-active);
}

.hrv-next-input-checkbox:not(.hrv-input-disabled)>.hrv-next-checkbox:hover+.hrv-next-checkbox--styled {
  background: white;
}

.hrv-next-input-checkbox:not(.hrv-input-disabled)>.hrv-next-checkbox:checked~.hrv-next-checkbox--styled {
  background: #2979FF;
}

.hrv2-pagination-dropdown--popup .hrv-dropdown-menu .hrv-dropdown-menu-item.active,
.hrv2-pagination-dropdown--popup-selectpage .hrv-dropdown-menu .hrv-dropdown-menu-item.active {
  background: var(--hrv-back-color-control);
}

.hrv-dropdown-menu-item:not(.hrv-dropdown-menu-item-disabled):hover,
.hrv-dropdown-menu-item:not(.hrv-dropdown-menu-item-disabled):hover div {
  background: var(--hrv-back-color-selected-item-hover);
}

.hrv-box-dropdown-name {
  flex: 1
}

.ps-relative
{
  position: relative;
}
.tab-wrapper
{
  margin-bottom: 20px;
}