.svg-icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    fill: currentColor;
    display: inline-block;
    vertical-align: middle;
}

.svg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
