.popover {
  max-width: none;
  z-index: 1;
}

.filter-content {
  display: flex;
}

.filter-content .filter-content-btn,
.filter-content .filter-content-key,
.filter-content .filter-content-value {
  margin-right: 7px;
}

.filter-content-value span.lable-is {
  padding: 10px 0;
}

.filter-content-value .hrv-select {
  margin-left: 10px;
  z-index: 9;
}

.hrv-next-input--stylized {
  line-height: 0.8rem;
}

.hrv-next-input--stylized .hrv-next-input-add-on {
  padding: 0.3rem 0.5rem 0.3rem 0;
}

input::placeholder {
  font-style: italic;
  font-size: 0.9em;
}

.text-search-wrapper {
  width: 100%;
}

.filter-content-value .hrv-select-selection--multiple .hrv-select-selection__choice {
  padding: 2px 15px 2px 3px;
}

.filter-content-value .hrv-select-selection--multiple .hrv-select-selection__choice__remove {
  right: 3px;
}

.filter-content-value .hrv-select-search__field__placeholder,
.hrv-select-selection__placeholder {
  top: 45%;
  margin-left: 0.5vw;
}

.filter-content-value .hrv-select-selection--multiple .hrv-select-selection__rendered {
  padding: 0px 6px 7px;
}