.hint-textbox {
  position: relative;
}

.hint-textbox-panel-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
}

div.hint-textbox-panel {
  position: absolute;
  border: 1px solid var(--hrv-border-light);
  margin-top: var(--hrv_distance_tn);
  background: white;
  border-radius: 4px;
  width: 100%;
  z-index: 3;
}

.user-info {
  padding: var(--hrv_distance_sm);
  border-radius: 2px;
}

.user-info.true,
.user-info.true:hover {
  background: var(--hrv-back-color-selected-item-hover);
  color: white;
}

.user-info:hover {
  background: var(--hrv-back-color-control-hover);
  color: white;
  cursor: pointer;
}