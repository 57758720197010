html {
  font-size: calc(14px + 0.1vw);
}

body {
  font-family: Helvetica, Arial, sans-serif;
  color: var(--hrv-fore-color);
  background: var(--hrv-back-color);
}

.small,
small {
  font-size: 12px;
}

.text-primary {
  color: #0279c7 !important;
}

.text-secondary {
  color: #9ca7b2 !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-disabled {
  color: var(--hrv-gray);
}

.dropdown-menu {
  border: none;
  -webkit-box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  border-radius: 3px;
  margin: 15px 0 0;
  padding: 5px 0;
  font-size: 14px;
  min-width: 150px;
}

.dropdown-menu:before {
  content: '';
  position: absolute;
  top: -21px;
  left: calc(50% - 10px);
  border-width: 10px;
  border-color: transparent;
  border-style: solid;
  border-bottom-color: rgba(39, 44, 48, 0.05);
}

.dropdown-menu:after {
  content: '';
  position: absolute;
  top: -18px;
  left: calc(50% - 9px);
  border-width: 9px;
  border-color: transparent;
  border-style: solid;
  border-bottom-color: #fff;
}

.dropdown-menu.dropdown-menu-left::before {
  left: 10px;
}

.dropdown-menu.dropdown-menu-left::after {
  left: 11px;
}

.dropdown-menu.dropdown-menu-right::before {
  right: 10px;
  left: auto;
}

.dropdown-menu.dropdown-menu-right::after {
  right: 11px;
  left: auto;
}

.dropdown-menu[x-placement='top-start'] {
  -webkit-box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 -2px 7px 1px rgba(39, 44, 48, 0.16);
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 -2px 7px 1px rgba(39, 44, 48, 0.16);
  margin: 0 0 15px;
}

.dropdown-menu[x-placement='top-start']:before {
  top: auto;
  bottom: -21px;
  border-color: transparent;
  border-top-color: rgba(39, 44, 48, 0.05);
}

.dropdown-menu[x-placement='top-start']:after {
  top: auto;
  bottom: -18px;
  border-color: transparent;
  border-top-color: #fff;
}

.dropdown-item {
  padding: 9px 15px;
  cursor: pointer;
  color: #212529;
  line-height: 18px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: #2979ff;
}

.transition-2s {
  transition: 2s;
}

.hrv-notification {
  background: transparent;
}
.hrv-notification-notice {
  margin-bottom: 0;
}
.hrv-notification-notice + .hrv-notification-notice {
  margin-top: var(--hrv_distance_sm);
}

.hrv-notification-notice-with-icon .hrv-notification-notice-message {
  font-size: large;
}
.notify-description {
  font-size: larger;
}

.bold {
  font-weight: bold;
}

.bold-trans {
  font-weight: bold;
  color: var(--hrv-fore-color-trans);
}

.italic {
  font-style: italic;
}

.text-right {
  text-align: right;
}

.block {
  display: block !important;
}

.flex {
  display: flex;
}
.flex.v-center {
  align-items: center;
}
.flex.h-center {
  justify-content: center;
}
.flex.center {
  justify-content: center;
  align-items: center;
}
.flex.right {
  justify-content: right;
}
.flex.space-between {
  justify-content: space-between;
  align-items: center;
}
.flex.stretch {
  align-items: stretch;
}
.flex > * + * {
  margin-left: var(--hrv_distance_lg);
}

.justify-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.edge {
  padding: 0.8%;
  border: 1px solid var(--hrv-back-color-edge-light);
  border-radius: 0.5rem;
  background: var(--hrv-back-color-inactive-light);
  width: fit-content;
}
.edge>* {
  border-radius: 0.4rem;
  background: var(--hrv-back-color-active);
}

.bg-white {
  background: white;
}

.fit-content {
  width: fit-content;
  height: fit-content;
  line-height: calc(10px + 0.3vw);
}

.no-data {
  color: var(--hrv-fore-color-trans);
  font-style: italic;
}

