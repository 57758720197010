.tickets-id{
    width: 12.5%;
}

.tickets-created-date{
    width: 14%;
}

.tickets-type
{
    width: 15%;
}

.tickets-emp{
    width: 12.5%;
}

.tickets-name
{
    width: 17.5%;
}