.tags-input-container {
    border: 0.5px solid #ccc7c7;
    padding: .5em;
    border-radius: 3px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .3em;
    min-height: 5.5vh;
}

.tag-item {
    background-color: #D2D2D2;
    display: inline-block;
    padding: 0.3em 0.45em;
    border-radius: 5px;
    color: #212121;
}

.tag-item-show {
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: 0.3em 0.45em;
    border-radius: 20px;
}

.tag-item .close {
    height: 20px;
    width: 20px;
    background-color: rgb(146 144 144);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.tag-item-show .close-show {
    height: 20px;
    width: 20px;
    background-color: rgb(146 144 144);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.lb-all-tag {
    text-align: right;
}

.tags-input {
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
}

.all-tag-item {
    /* width: 80%;
    word-wrap:break-word; */
    padding: .2em;
    border-radius: 3px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
}

.modal-dialog .modal-content .modal-footer .btn.btn-secondary {
    background-color: #2979FF;
}