.app-list {
  width: 100%;
}

.hrv-table .data-list-header-cell.app-name-col {
  min-width: 17vw;
  padding-left: 4.2rem;
  text-align: left;
}

.app-partner-col {
  width: 15rem;
}

.hrv-table .data-list-header-cell.app-cost-col,
.hrv-table .data-list-cell.app-cost-col {
  width: 8rem;
  text-align: right;
}

.hrv-table .data-list-header-cell.app-trial-day-col {
  text-align: right;
}

.hrv-table .data-list-header-cell.app-date-col,
.hrv-table .data-list-cell.app-date-col {
  width: 15rem;
  /* text-align: center; */
}

.hrv-table .data-list-header-cell.app-status-col,
.hrv-table .data-list-cell.app-status-col {
  width: 8rem;
  text-align: center;
}

.app-image-name {
  display: flex;
  align-items: center;
}

.app-image-col {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}
