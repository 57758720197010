.shopplans-detail {
    width: 100%;
}
.input-name-shopplan {
    width: 30vw;
    height: 34px;
    font-size: 13px;
    line-height: 20px;
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 2vh;
}

.input-name-metadata {
    height: 34px;
    font-size: 13px;
    line-height: 20px;
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 2vh;
}

.hrv-select-selection-selected-value {
    color: black;
    font-size: 15px;
}

.tb-list-meta {
    height: 3vh;
}

.shopplans-detail .btn-delete .hrv-btn.hrv-btn-link {
    color: #fe3737;
}

.shopplans-detail .btn-edit .hrv-btn.hrv-btn-link {
    color: #2979FF;
}

.shopplans-detail .item-meta {
    font-size: 12px;
}

.shopplans-detail .list-btn {
    display: flex;
    justify-content: flex-end;
}

.btn-add-metadata {
    text-align: right;
}

.panel-heading {
    display: flex;
    justify-content: space-between;
}

.checkbox-shopplan {
    margin-left: 4px;
    margin-bottom: 2vh;
}

.shopplans-detail .layout-container .hrv-btn.hrv-btn-primary {
    margin-left: 0px;
}


.input-number-shopplan {
    width: 30vw;
    height: 34px;
    font-size: 13px;
    margin-bottom: 2vh;
    padding-right: 10px;
}

.input-number-shopplan .next-input-add-on {
    padding-left: 0.9rem;
}

.shopplans-detail input:focus {
    outline: none;
}

.shopplans-detail .hrv-input-number-input {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.shopplans-detail .panel-title,
.product-detail .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 17px;
    color: black
}

.shopplans-detail .panel-title-shopplan,
.product-detail .panel-title-shopplan {
    padding: 5px;
    margin-top: 3px;
    margin-bottom: 0;
    font-size: 17px;
    color: black
}

.body-shopplan {
    padding: 10px;
}

.panel-heading-shopplan {
    padding: 3px 10px;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #fafcfc;
    border-bottom: solid 1px #e9eff3;
    color: #505357;
}

.shopplans-detail .hrv-table-container{
    margin-bottom: 0;
}
.product-detail .hrv-table-container{
    margin-bottom: 5px;
}
.shopplans-detail .hrv-box-dropdown-have-data,
.shopplans-detail .hrv-box-dropdown-empty {
    margin-bottom: 13px;
    font-size: 13px;
    width: 30vw;
}

.shopplans-detail .hrv-box-dropdown {
    min-height: 30px;
}

.shopplans-detail .hrv-table tbody td,
.shopplans-detail .hrv-table tfoot td {
    padding: 6px 10px;
}

.shopplans-detail .listview-content {
    max-height: 41.5vh;
    overflow: auto;
}

.shopplans-detail .next-input--stylized .next-input.next-input--invisible {
    text-align: left;
    padding-left: 9px;
}
.shopplans-screen
{
    padding-bottom: 8vh;
}