.user-list-wrapper {
  width: 100%;
}

.user-userworking-id {
  text-align: left !important;
}

.user-username {
  text-align: left !important;
}

.payout-ammount {
  text-align: right !important;
}

.payout-status, .payout-order-status {
  text-align: center !important;
}

.btn-user-config {
  padding: var(--hrv_distance_sm);
  min-height: auto;
}
.user-list-wrapper .menu-item-icon {
  color: #fdfeff ;
}