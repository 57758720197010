.data-save-wrapper {
  margin-top: 0.5rem;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease-in 0.3s;
  background: var(--hrv-menu-back-color);
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 52px;
  left: 0;
}

.data-save-wrapper .hrv-btn-group--item {
  position: absolute;
  bottom: 4px;
  right: 6%;
}

.data-save-wrapper button {
  min-width: 4rem;
}

.data-save-wrapper.has-changed {
  visibility: visible;
  opacity: 1;
  background: #05a9f4;
  z-index: 1;
}

.data-save-wrapper.has-changed .hrv-btn-group--item-1 {
  width: 11vw;
  background: var(--hrv-menu-back-color);
}