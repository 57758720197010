.horizontal {
  display: flex;
  flex-wrap: wrap;
}

.horizontal *+* {
  margin-top: 0;
  margin-left: 1.5rem;
}

.checklist-item {
  display: flex;
  align-items: center;
  color: #8f989e;
}

.checklist-item * {
  cursor: pointer;
}

.checklist-item input+* {
  margin-left: 0.5rem;
}