.payout-list-wrapper {
  width: 100%;
}

.payout-partner {
  text-align: left !important;
}

.payout-ammount {
  text-align: right !important;
}

.payout-status, .payout-order-status {
  text-align: center !important;
}
