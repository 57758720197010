.menu-item-wrapper {
  padding: 0.8rem;
  color: #212B36;
  font-size: 0.9em;
  font-weight: bold;
}
.menu-item-wrapper:hover {
  cursor: pointer;
  color: var(--hrv-menu-item-color-hover);
  border-radius: 0.2rem;
}
.menu-item-active {
  color: #007ace;
  border-radius: 0.2rem;
}
.menu-item-active:hover {
  color: #0c9df7;
}

.menu-item-icon {
  color: #919eab
}

.menu-item-active .menu-item-icon {
  color: #007ace
}
.menu-item:hover,
.menu-item:hover .menu-item-icon {
  color: #0c9df7
}

.menu-item {
  display: flex;
  margin: auto;
  align-items: center;
}
.menu-item-sub
{
  display: flex;
  margin-left: 20px;
  margin-top: 8px;
  color: black;
}

.menu-item-text {
  margin-left: 0.5rem;
}
.menu-item-tooltip {
  display: none;
}

@media screen and (max-width: 700px) {
  .menu-item {
    display: none;
  }
  .menu-item-tooltip {
    display: block;
  }
}
