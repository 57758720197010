.hrv-table-container {
  /* max-height: calc(75vh - 50px); */
  overflow: auto;
  border: 1px solid var(--hrv-border-light);
  border-radius: 4px;
  margin-bottom: var(--hrv_distance_lg);
}

.hrv-table {
  border-radius: 4px;
}

.hrv-table .data-list-header-cell {
  color: #456;
  padding: var(--hrv_distance_lg);
  text-align: center;
  font-weight: 600;
}

.hrv-table .data-list-header-cell.checkbox-cell {
  padding-bottom: 0.8rem;
  width: 2rem;
}

.data-list-header-cell.object,
.data-list-header-cell.string {
  text-align: left;
}

.data-list-header-cell * {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.theme-status {
  display: inline-block;
}

.data-list-sort-header {
  text-align: center;
}

.data-list-value .data-filter-wrapper {
  transition: opacity 0.5s;
}

.data-list-value:hover .data-filter-wrapper {
  opacity: 0.6;
  transition: opacity 0.5s;
}

.hrv-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.hrv-table thead th {
  background: #fafbfc;
  box-shadow: inset 0px -1px 0px #eeee;
}

.hrv-table .data-list-cell {
  padding: var(--hrv_distance_xl);
  font-size: 0.9rem;
  text-align: center;
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-list-cell.object,
.data-list-cell.string {
  text-align: left;
}

.hrv-table tr:hover td {
  background: linear-gradient(to bottom, #fff, #fff, #fff8);
}

.hrv-next-input-checkbox {
  z-index: 0;
}

.hrv-table .hrv-next-checkbox--styled {
  background: var(--hrv-back-color-active);
  border: 1px solid var(--hrv-border-light);
}

.hrv-table .hrv-next-checkbox--styled:hover {
  background: white;
}

.hrv-table thead th:first-child,
.hrv-table tbody td:first-child,
.hrv-table tfoot td:first-child {
  padding: 0.5rem 1rem;
}

.hrv-table thead th:first-child {
  border-radius: 4px 0 0 0;
}

.hrv-table thead th:last-child {
  border-radius: 0 4px 0 0;
}

.hrv-table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

.hrv-table tbody tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}

.hrv-table tbody td {
  line-height: normal;
  box-shadow: inset 0px -1px 0px #eeee;
}

.data-list-sort-label+.svg-wrapper {
  margin-left: 0.5rem;
}

.data-table-wrapper+.data-paging-wrapper {
  margin-top: 0.5rem;
}

.data-paging-wrapper {
  margin-top: 0.5rem;
}

.hrv2-pagination {
  justify-content: flex-start;
}

.hrv2-pagination-dropdown--number {
  font-size: 0.9rem;
  line-height: 0.9rem;
}

.hrv2-pagination-dropdown--number+.hrv2-pagination-dropdown--icon {
  margin-left: 0.5rem;
}

.hrv2-pagination-dropdown .hrv2-pagination-dropdown--btn {
  padding: 0.4rem 0.6rem;
  width: fit-content;
}

.hrv2-pagination-dropdown--text {
  min-width: 11rem;
  line-height: inherit;
  display: flex;
  align-items: center;
}

.hrv2-pagination--listpage {
  padding-left: 1rem;
  margin: 0;
}

.hrv-next-input--stylized .hrv-next-input.hrv-next-input--invisible {
  outline: none;
}

.hrv-next-input--stylized .hrv-next-input__add-on--after {
  padding: 0;
}

.hrv2-pagination--item+.hrv2-pagination--item {
  margin-left: 0.5rem;
}

@media screen and (max-width: 700px) {}

.data-list-header-cell-font-normal {
  font-weight: 500 !important;
  color: black !important;
}