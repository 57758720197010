.format-currency {
  text-align: left;
  margin-left: 5px;
}

.download {
  color: var(--hrv-lighter-blue);
}

.download *:hover {
  color: var(--hrv-light-blue);
}

.download-icon {
  width: var(--hrv_distance_xl);
  height: var(--hrv_distance_xl);
  cursor: pointer;
  margin-right: var(--hrv_distance_sm);
}

.download-link {
  margin-left: 0.5rem;
  cursor: pointer;
}