.data-detail-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.data-detail-bottom {
    flex-basis: 100%;
}

.data-detail {
    width: 49%;
}

.data-detail-info {
    display: flex;
    align-items: center;
    padding: 0.3rem 0.1rem;
}

.data-detail-info .hrv-next-input {
    padding: 0.3rem;
}

.info-name {
    margin-right: 0.5rem;
    font-weight: 600;
    min-width: fit-content;
}

.info-content {
    width: 100%;
}