
.partner-info-wrapper {
    padding: 0.5rem;
}
.shop-detail-wrapper .partner-app-price-cost .format-currency{
    text-align: center;
}
.shop-detail-wrapper .partner-app-trial-days,
.shop-detail-wrapper .partner-app-created-date{
    text-align: center;
}
.shop-detail-wrapper .partner-theme-price-cost,
.shop-detail-wrapper .partner-theme-created-date{
    text-align: center;
}

.tab-content-wrapper .partner-theme-price-cost .format-currency{
    text-align: center;
}