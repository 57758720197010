.shop-transaction-totalAmount{
    width: 10%;
}

.shop-transaction-id{
    width: 12.5%;
}

.shop-transaction-created-date{
    width: 14%;
}

.shop-transaction-type
{
    width: 15%;
}

.shop-transaction-status{
    width: 12.5%;
}

.shop-transaction-partner-name
{
    width: 17.5%;
}
.shop-transaction-product{
    width: 18.5%;
}
.shop-transaction-type,
.shop-transaction-status{
    text-align: center;
}