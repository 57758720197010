.tab-item {
    height:35px;
    cursor: pointer;
    border: 1.5px solid  var(--hrv-light-white-blue);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8a8989;
    box-sizing: border-box;
    margin-bottom: 1px;
}
.tab-name{
    font: 14px Roboto-normal;
    color:  #303030;
    padding: 4px 20px ;
    font-weight: 500;
}
.tab-item:first-child {
    border-top-left-radius: 0.3rem;
}
.tab-item + .tab-item {
    border-left: none;
}

.tab-active{
    cursor: default;
    background: #cecbcb;
    color: black;
}