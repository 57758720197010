.class-subscription .hrv-popover {
    border: 1px solid #E0E0E0;
    z-index: 1060;
    box-shadow: 0px 5px 10px rgba(33, 33, 33, 0.2);
}

.hrv-box-dropdown-shopplan .hrv-box-dropdown-wrapper-content {
    width: 500px;
}

.class-subscription {
    padding: 10px 0;
}