.website-info-wrthemeer {
  padding: 0.5rem;
}
.partner-theme-name {
  width: 20%;
}
.partner-theme-partner-name {
  width:15%;
}
/* .partner-theme-price-type {
    width: 150px;
} */
.partner-theme-price-cost {
  width: 20%;
}

.partner-theme-download {
  width: 12.5%;
  text-align: center;
}

.partner-theme-created-date {
  width: 20%;
}
.partner-theme-status {
    width: 12.5%;
    text-align: center;
}
