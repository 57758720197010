.shop-detail-wrapper {
  width: 100%;
}

.website-info-wrapper {
  padding: 0.5rem;
}
.transaction-id {
  width: 12rem;
}
.transaction-website-name {
  width: 15rem;
}
.transaction-partner-name {
  width: 15rem;
}
.transaction-created-date {
  width: 13rem;
}
.transaction-type {
  width: 10rem;
}
.transaction-product {
  width: 14rem;
}
.transaction-status {
  width: 9rem;
}
.transaction-totalAmount {
  width: 8rem;
  text-align: center;
}
td:has(p.itemNext)
{
  border-top: solid 1px #c9c9ca;
}