.status-website{
    width: auto;
    padding: 3px;
    border:1px solid rgb(58, 107, 11);
    background-color: rgb(173, 229, 202);
    border-radius: 5px;
}
.status-websited{
    width: auto;
    padding: 3px;
    border:1px solid rgb(237, 50, 8);
    background-color: rgb(240, 177, 158);
    border-radius: 5px;
}