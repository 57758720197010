.treecheckbox-node-container2 .menu-item-icon{
    opacity: 0.6;
}
.one-node{
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.treecheckbox-node-container-parent{
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    border: solid 1px #dee5eb;
}
.hrv-next-label--switch{
    font-size: 0.8rem;
}
.hrv-next-input-checkbox.function-3{
    margin-bottom: 0.4rem;
}


.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd
}
.treecheckbox-node-container2{
    margin-left: 0.5vw;
}
.treecheckbox-node-container3{
    margin-left: 1.5vw;
}
.role-detail{
    margin-bottom: 10px;
}