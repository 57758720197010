.status-selector {
  position: relative;
}

.status-selected {
  cursor: pointer;
}

.status-list {
  position: absolute;
  top: -0.5rem;
  left: -2.5rem;
  width: max-content;
  margin-top: 0.2rem;
  padding: var(--hrv_distance_tn);
  z-index: 1;
  border-radius: 0.2rem;
  background: white;
  box-shadow: 0 0 0.1rem var(--hrv-lightest-blue);
}

.status-remaining {
  border-radius: 0.2rem;
  background: white;
  width: fit-content;
  line-height: 1rem;
}
.status-remaining + .status-remaining {
  margin-top: 0.2rem;
}
.status-remaining .hrv-badges {
  cursor: pointer;
}