.product-detail {
    width: 100%;
}

.product-screen {
    margin-bottom: 80px;
}

.product-detail .list-btn {
    display: flex;
    justify-content: flex-end;
}

.product-detail .btn-delete .hrv-btn.hrv-btn-link {
    color: red;
}

.product-detail .btn-edit .hrv-btn.hrv-btn-link {
    color: #2979FF;
}

.product-detail .item-meta {
    font-size: 12px;
}
.key-meta{
    font-size: 12px;
    width: 30%;
}
.value-meta{
    font-size: 12px;
    width: 60%;
}
.status-shopplan{
    text-align: center;
    margin-right: 20px;
}
.panel-heading-list-shopplan {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #fafcfc;
    border-bottom: solid 1px #e9eff3;
    color: #505357;
}

.product-detail .list-metadatas .hrv-table tbody td,
.product-detail .list-metadatas .hrv-table tfoot td {
    padding: 6px 10px;
}

.product-detail .panel-default {
    border-radius: 5px;
    border: solid 1px #dee5eb;
}

.render-product {
    display: flex;
}

.product-detail .lb-name-shopplan {
    width: 13%;
}
.panel-default
{
    background: white;
}