.website-info-wrapper {
    padding: 0.5rem;
}
.partner-app-name {
    width: 15rem;
}
.partner-app-partner-name {
    width: 15rem;
}
.partner-app-price-type {
    width: 10rem;
}
.partner-app-price-cost {
    width: 12rem;
    text-align: right;
    display: flex;
    justify-content: center;
}
.partner-app-price-mid {
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
}
.partner-app-trial-days {
    width: 10rem;
    text-align: right;
}
.partner-app-created-date {
    width: 15rem;
}
.partner-app-status {
    width: 10rem;
    text-align: center;
}