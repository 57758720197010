
.permission-user .flex + .flex {
  margin-top: var(--hrv_distance_lg);
}

.permission-user .flex *:first {
  flex: 1;
  min-width: 50%;
}

.permission-user .flex > * + * {
  flex: 2;
  margin-left: var(--hrv_distance_lg);
}

.permission-user .email,
.permission-user .roles {
  width: 20%;
}

.permission-user .roles + .react-checkbox-tree {
  font-size: calc(0.8em + 0.1vw);
}

.permission-user .rct-collapse {
  display: none;
}

.permission-user-scopes {
  max-height: 50vh;
  overflow: auto;
}

.permission-user-btn {
  margin-top: var(--hrv_distance_lg);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.permission-user-btn > button {
  min-width: 5rem;
}

.permission-detail-wrapper .tab-content {
  border: 1px solid var(--hrv-lightest-white-blue);
  border-radius: 0.5rem;
  background: var(--hrv-light-white);
  min-height: 25rem;
  padding: var(--hrv_distance_lg);
  overflow: hidden;
}

.permission-client-scope {
  margin-top: var(--hrv_distance_sm);
  max-width: 400px;
}

.permission-client-scope .hrv-badges-body{
  display: flex;
}
.permission-client-scope .hrv-badges + .hrv-badges {
  margin-top: var(--hrv_distance_sm);
  margin-left: calc(0.5rem + 0.3vw);
}
.permission-cient-icon{
  margin-left: var(--hrv_distance_sm);
}

.permission-cient-icon:hover{
  background: #22ff9355;
  font-weight:600;
  cursor: pointer;
}