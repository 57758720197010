.ui-layout-error {
    height: calc(100vh - 50px);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.ui-layout-error--box {
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    text-align: center;
    margin: 30px 0;
}

.ui-layout-error--icon {
    max-width: 340px;
    max-height: 215px;
    margin: auto;
}

.ui-layout-error--icon svg {
    width: 100%;
}

.ui-layout-error--icon+.ui-layout-error--content {
    margin-top: 40px;
}

.ui-layout-error--title {
    font-size: 20px;
    line-height: 23px;
    color: #0279C7;
    font-weight: bold;
}

.ui-layout-error--title+.ui-layout-error--description {
    margin-top: 5px;
}

.ui-layout-error--description {
    font-size: 14px;
    line-height: 16px;
}

.ui-layout-error--action {
    margin-top: 35px;
}