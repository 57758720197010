
:root {
  --hrv-font: Helvetica,Arial,sans-serif;

  /* Colors */

  --hrv-light-red: #d35b57;
  --hrv-red: #ba4240;
  --hrv-dark-red: #af4541;
  --hrv-darker-red: #a04040;
  --hrv-disabled-red: #c35b5770;

  --hrv-lightest-white-blue: #e5eaf5;
  --hrv-lighter-white-blue: #cde;
  --hrv-light-white-blue: #c5d5e5;
  --hrv-white-blue: #abd;
  --hrv-lightest-blue: #62abea;
  --hrv-lighter-blue: #529bda;
  --hrv-light-blue: #428bca;
  --hrv-blue: #327bba;
  --hrv-dark-blue: #226baadd;
  --hrv-darker-blue: #125b9add;
  --hrv-darkest-blue: #024b8a;
  --hrv-darkest-blue-trans: #024b8abb;
  --hrv-disabled-blue: #3498;

  --hrv-light-white: #fbfdff;
  --hrv-white-trans: #f6f7f8aa;
  --hrv-white: #f6f7f8;
  --hrv-dark-white: #ebedef;
  --hrv-darker-white: #dbdddf;
  --hrv-disabled-white: #eee;
  --hrv-translucent-white : #efefefaa;

  --hrv-lightest-gray: #eee;
  --hrv-lighter-gray: #ddd;
  --hrv-light-gray: #aaa;
  --hrv-gray: #888;
  --hrv-dark-gray: #666;
  --hrv-darker-gray: #444;
  --hrv-darkest-gray: #222;
  --hrv-translucent-gray: #8888;

  --hrv-black: #123;
  --hrv-translucent-black: #1234;

  --hrv-fore-color: #234;
  --hrv-fore-color-invert: var(--hrv-white);
  --hrv-fore-color-light: #3f4f5f;
  --hrv-fore-color-trans: #234a;
  --hrv-back-color: #f6f5f4;
  --hrv-back-color-trans: #e0e5ef88;
  --hrv-back-color-active: #fdfeff;
  --hrv-back-color-inactive: #1234;
  --hrv-back-color-edge: #6789;
  --hrv-back-color-inactive-light: #d0d8df44;
  --hrv-back-color-edge-light: #abcd;
  --hrv-back-color-control: #476699;
  --hrv-back-color-control-hover: #476699d0;
  --hrv-back-color-selected-item-hover: #476699;

  --hrv-header-color: #346;

  --hrv-link-hover-color: #5c6ac4;

  --hrv-border: var(--hrv-back-color-edge);
  --hrv-border-light: #e0e0e0e0;
  --hrv-border-lightest: #eeee;

  --hrv-menu-back-color: #fafbfc;
  --hrv-menu-item-color: #456;
  --hrv-menu-item-color-hover: #123;
  --hrv-menu-item-back-color-hover: #eaeffa;
  --hrv-menu-item-color-active: var(--hrv-link-hover-color);

  /* ------------------------ */

  /* Distances */

  --hrv_distance_tn: calc(0.1rem + 0.1vw);
  --hrv_distance_xs: calc(0.2rem + 0.2vw);
  --hrv_distance_sm: calc(0.3rem + 0.3vw);
  --hrv_distance_md: calc(0.4rem + 0.4vw);
  --hrv_distance_lg: calc(0.5rem + 0.5vw);
  --hrv_distance_xl: calc(0.6rem + 0.6vw);
  --hrv_distance_xxl: calc(0.7rem + 0.7vw);

  /* ------------------------ */

}

#react-app {
  display: flex;
  color: #012;
}

.break-word {
  word-break: break-word;
}

.text-note {
  font-size: 13px;
  color: #999;
}

.text-title-field {
  margin: 0 0 8px;
  font-weight: 600;
  display: block;
  text-transform: uppercase;
}

.next-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  text-transform: initial;
  letter-spacing: initial;
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-pointer {
  cursor: pointer;
}

.list-box-items {
  margin-bottom: 10px;
}

.list-box-items .list-head-items {
  margin-bottom: 10px;
  font-weight: 500;
}

.list-box-items .list-body-items {
  margin-bottom: 10px;
}

.list-box-items .list-body-items li {
  list-style-type: none;
}

.list-box-items .list-body-items li label {
  font-weight: normal;
  display: inline-block;
}

.font-weight-500 {
  font-weight: 500;
}

.full-width {
  width: 100%;
}

.width-20px {
  width: 20px;
}

.width-30px {
  width: 30px;
}

.width-50px {
  width: 50px;
}

.width-80px {
  width: 80px;
}

.width-100px {
  width: 100px;
}

.width-120px {
  width: 120px;
}

.min-height-200px {
  min-height: 200px;
}

.min-width-80px {
  min-width: 80px;
}

.min-width-120px {
  min-width: 120px;
}

.min-width-250px {
  min-width: 250px;
}

.min-width-280px {
  min-width: 280px;
}

.min-width-300px {
  min-width: 300px;
}

.min-width-320px {
  min-width: 320px;
}

.max-width-100px {
  max-width: 100px;
}

.max-width-150px {
  max-width: 150px;
}

.max-width-200px {
  max-width: 150px;
}

.max-width-280px {
  max-width: 280px;
}

.max-width-350px {
  max-width: 350px;
}

.max-width-15percent {
  max-width: 15%;
}

.max-width-50percent {
  max-width: 50%;
}

.pd-0-5 {
  padding: 0 5px !important;
}

.pd-0-10 {
  padding: 0 10px;
}

.pd-10-20 {
  padding: 10px 20px !important;
}

.pd-l-25 {
  padding-left: 25px !important;
}

.pd-l-5 {
  padding-left: 5px !important;
}

.pdl10 {
  padding-left: 10px !important;
}

.pd-l-20 {
  padding-left: 20px !important;
}

.pd-r-2 {
  padding-right: 2px !important;
}

.pd-r-10 {
  padding-right: 10px !important;
}

.pdr20 {
  padding-right: 20px !important;
}

.pd-t-3 {
  padding-top: 3px !important;
}

.pd-t-20 {
  padding-top: 20px !important;
}

.pd-b-20 {
  padding-bottom: 20px !important;
}

.pdlr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.m0 {
  margin: 0px !important;
}

.m20 {
  margin: 20px;
}

.mt2-percent {
  margin-top: 2%;
}

.mt3-percent {
  margin-top: 3%;
}

.mt5-percent {
  margin-top: 5%;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-md {
  margin-top: var(--hrv_distance_md);
}

.mt10res {
  margin-top: calc(10px + 0.3vw);
}

.mt0 {
  margin-top: 0px;
}

.mt3 {
  margin-top: 3px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
  width: 48%;
  margin-bottom: 1vh;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb-sm {
  margin-bottom: var(--hrv_distance_sm);
}
.mb-md {
  margin-bottom: 1vh;
}
.mb-lg {
  margin-bottom: var(--hrv_distance_xl);
}

.ml5 {
  margin-left: 5px;
}

.ml20 {
  margin-left: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}


/* PADDING */

.pt1-percent {
  padding-top: 1%;
}

.pd0 {
  padding: 0px !important;
}

.pd7 {
  padding: 7px;
}

.pd10 {
  padding: 10px;
}

.pd50 {
  padding: 50px;
}

.pd20 {
  padding: 20px;
}

.flex-1 {
  flex: 1;
}

.hide-chevron {
  -webkit-appearance: none;
}

.border-top {
  border-top: 1px solid #dfe3e8;
}

.border-radius-0 {
  border-radius: 0px;
}

.border-radius-4px {
  border-radius: 4px;
}

.w-100 {
  width: 100%;
}

.text-ellipsis {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-content {
  padding: 10px;
}

.modal .hrv-modal .modal-content .hrv-modal-header,
.modal .hrv-modal .modal-content .hrv-modal-header + .hrv-modal-body,
.modal .hrv-modal .modal-content .hrv-modal-body + .hrv-modal-footer {
  padding: 1rem;
}
.pl-1{
  padding-left: 0.25rem;
}