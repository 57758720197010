.shop-user {
  /* min-width: 30rem; */
}

.shop-user .flex + .flex {
  margin-top: var(--hrv_distance_lg);
}

.shop-user .flex *:first {
  flex: 1;
  min-width: 50%;
}

.shop-user .flex > * + * {
  flex: 2;
  margin-left: var(--hrv_distance_lg);
}

.shop-user-scopes {
  max-height: 50vh;
  overflow: auto;
  /* padding: var(--hrv_distance_sm); */
}

.shop-user-btn {
  margin-top: var(--hrv_distance_lg);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop-user-btn > button {
  min-width: 5rem;
}

.shop-detail-wrapper .tab-content {
  /* border: 1px solid var(--hrv-lightest-white-blue); */
  border-radius: 0.5rem;
  background: var(--hrv-light-white);
  padding: var(--hrv_distance_lg);
  overflow: hidden;
}

.popup_user_access .popup
{
  width: 700px;
}
.hrv-loading-btn
{
  min-height: auto;
}
.badges_owner
{
  padding: 2px !important;
}
.badges_owner .hrv-badges-body
{
  font-size: 10px;
}