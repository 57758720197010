.header-wapper {
  background-color: #094e9d;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 3.5rem;
}
.header-logo {
  width: 11vw;
  background-color: #084183;
  height: 3.5rem;
  padding-top: 10px;
}
.header-log-img {
  height: 30px;
  width: 100%;
}

.header-title {
  font-size: 15px;
  font-weight: bolder;
}

.header-logo-account {
  height: 40px;
  width: 40px;
  background-color: var(--hrv-darkest-blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.header-logo-account img {
  border-radius: 50%;
}

.header-user {
  position: relative;
  margin-right: 10px;
}

.header-modal {
  width: 200px;
  height: fit-content;
  background-color: var(--hrv-white);
  position: absolute;
  right: -40px;
  top: -9px;
}
.hrv-popover .header-modal * {
  background: inherit;
}
.header-modal *:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.account-info {
  padding: 20px;
}

.account-action {
  list-style: none;
  width: 100%;
}

.account-action-item {
  width: 100%;
  padding: 5px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: inherit;
}

.account-action-item:hover {
  background: rgb(20, 101, 241);
  color: white !important;
}

.account-infor-email {
  font-size: 12px;
  font-style: italic;
  color: #a0a0a0;
}
