
.page-content {
  justify-content: center;
  align-items: flex-start;
}

.page-content .page-section {
  flex: 1 1;
  width: 100%;
}

.page-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  background-color: #f6f5f4;
  margin-bottom: 80px;
}

.page-section-wrapper .page-section {
  flex: 1 1;
  white-space: nowrap;
  background-color: #f6f5f4;
}
.page-section-wrapper .page-section + .page-section {
  flex: 3 1;
  max-width: 74%;
}

.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.page-container_header_sidebar, .page-container_fill {
  flex: 1 1;
}

.page-header-secondary-item {
  user-select: none;
  cursor: pointer;
}

.page-header-action-groups-menu {
  position: absolute;
  right: 0;
  top: 0;
}

.anotated-header {
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}

.anotated-description {
  color: #6c757d;
  white-space: normal;
}
.hrv-loading-box.hrv-loading-btn {
  min-height: auto;
}
.div-upload-image {
  display: flex;
}

.div-upload-image-item {
  position: relative;
  cursor: pointer;
}
.div-upload-image-item svg {
  position: absolute;
  top: 50%;
  left: 44%;
  visibility: hidden;
}
.div-upload-image-item:hover img {
  opacity: 0.5;
}
.div-upload-image-item:hover svg {
  visibility: visible;
}
.hrv-breadcrumb-text {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}
