.shop-list-wrapper {
  width: 100%;
}

.hrv-table .data-list-header-cell.shop-name,
.hrv-table .data-list-cell.shop-name {
  text-align: left;
}

.shop-list-wrapper .status-wrapper .hrv-badges {
  padding: 0px 4px 0px !important;
  margin: 2px
}
.shop-list-wrapper .status-wrapper{
  text-align: center;
}

.shop-list-wrapper .data-list-header-cell.object.theme-status {
  padding-left: 30%;
}
.is-deleted{
  color: #616a75 ;
  text-decoration-line: line-through;
}
.data-list-cell.object.shop-total-store,
.data-list-cell.object.shop-total-user,
.data-list-cell.object.shop-total-page{
  text-align: center;
}
.data-list-header-cell.object.shop-id{
  padding-left: 2vw;
}