.shopplans-list{
    width: 100%;
}
.plans-name-col,
.plans-product-col,
.plans-isactive-col{
    width: 15%;
}
.plans-isactive-col{
    text-align: center !important;
}
.plans-cost-col,
.plans-ref-col{
    width: 15%;
}
.plans-planCode-col{
    width: 20%;
}
.shopplans-list .empty-state-button{
    text-align: right;
    margin-bottom: 10px;
}
.shopplans-list .data-list-header-cell *{
    display: initial;
}