.textbox-debounce-wrapper {
  background: white;
  caret-color: transparent;
}

.textbox-debounce-wrapper input,
.textbox-debounce-wrapper textarea {
  caret-color: initial;
}

.next-input,
.next-input--stylized {
  border: 1px solid #e3e9ed;
  color: #212121;
  padding: 10px;
  height: 40px;
  border-radius: 4px;
  line-height: 18px;
  display: block;
  width: 100%;
  transition: all 0.2s ease-out;
  outline: none;
}

.next-input--stylized {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 0;
  justify-content: flex-start;
  /* font-size: 1rem; */
  font-weight: bold;
}

.next-input--stylized.textbox-debounce-input,
.hrv-ui-textarea {
  /* height: fit-content; */
  padding: calc(2px + 0.3vw) calc(3px + 0.3vw);
  line-height: calc(15px + 0.5vw);
}

/* 
.hrv-ui-textarea {
  padding: calc(2px + 0.4vw) calc(3px + 0.4vw);
} */

.hrv-select-selection:focus,
.hrv-select-selection:hover,
.hrv-next-input:focus,
.hrv-next-input:hover,
.next-input--stylized:focus-within,
.next-input--stylized:hover,
.hrv-input-number-focused,
.hrv-input-number:hover,
.hrv-ui-textarea:not([disabled]):not([readonly]):focus,
.hrv-ui-textarea:hover,
.input-full-width:hover,
.input-full-width:focus-within,
.hrv-ui-textarea:focus,
.hrv-ui-textarea:hover,
.cke:hover {
  outline: 1px solid var(--hrv-lightest-blue);
  border-color: var(--hrv-light-white-blue) !important;
  /* box-shadow: 0 0 0 1px var(--hrv_lightest_blue); */
  transition: none;
}

.hrv-ui-textarea:focus,
.hrv-next-input:focus,
.hrv-input-number-focused,
.hrv-input-number-focused:hover,
.next-input--stylized:focus-within,
.input-full-width:focus-within,
.hrv-ui-textarea:focus,
.cke_inner:focus-within {
  box-shadow: 0 0 0.5rem var(--hrv-lightest-blue);
}

.next-input--stylized .next-input.next-input--invisible {
  border: none;
  padding: 0;
  border-radius: 0;
  height: auto;
}

.next-input:focus,
.next-input--is-focused {
  border-color: #0052cc;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  box-shadow: none;
}

.hrv-next-input--readonly,
.hrv-next-input--readonly .hrv-next-input,
.hrv-next-input[readOnly],
.hrv-next-input--disabled,
.hrv-next-input--disabled .hrv-next-input,
.hrv-next-input[disabled],
.hrv-ui-textarea[disabled],
.hrv-ui-textarea[readonly],
.next-input--readonly {
  color: #000000c4;
  /* color: var(--hrv-fore-color-trans); */
  /* background-color: var(--hrv-back-color-active); */
}

.next-input-add-on {
  align-self: center;
  flex: none;
  white-space: nowrap;
  height: 16px;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 18px;
  cursor: pointer;
  padding-left: 0.2rem;
}

input[readonly] * {}

.textbox-debounce-wrapper label {
  min-width: calc(148px + 0.2vw);
}