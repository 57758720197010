a,
a:not([href]):not([class]) {
  cursor: pointer;
  color: var(--hrv-light-blue);
  text-decoration: none;
}

a:hover,
a:not([href]):not([class]):hover {
  text-decoration: none;
  color: var(--hrv-light-blue);
}

a.link:hover {
  text-decoration: underline;
}

.is-deleted a {
  color: #616a75;
  text-decoration-line: line-through;
}
.card-theme{
  display: flex;
}
.card-theme-install{
  margin-right: 10px;
  margin-top: 5px;
}