.transaction {
    display: flex;
    padding: 5px;
}

.render-body {
    width: 75%;
}

.transactionInfo {
    width: 100%;
    border: 0.3px solid rgb(207, 199, 199);
    padding-bottom: 5px;
}

.WebsiteInfo {
    padding-left: 10px;
    width: 25%;
}

.transaction-information {
    display: flex;
    justify-content: space-between;
    border: 0.3px solid rgb(207, 199, 199);
    height: 50px;
}

.lb-info {
    padding-left: 20px;
    padding-top: 15px;
}

.general-information {
    width: 100%;
    display: flex;
}

.text-left {
    width: 50%;
}

.text-righ {
    width: 50%;
}

.package-info {
    width: 100%;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

}

.package-info-left {
    width: 50%;
}

.package-info-right {
    display: flex;
    width: 50%;
}

.package-info-child {
    border: 0.3px solid rgb(207, 199, 199);
    border-bottom: 0;
}

.calculation {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.3px solid rgb(207, 199, 199);
    border-bottom: 0;
    border-left: 0;
}

.result {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.3px solid rgb(207, 199, 199);
    border-bottom: 0;
    border-left: 0;
}

.total-money {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    border: 0.3px solid rgb(207, 199, 199);
    text-align: -webkit-center;
    padding: 7px;
    font-size: 15px;
}

.total-money-child1 {
    width: 50%;
}

.total-money-child2 {
    display: flex;
    width: 50%;
}

.lb-total-money {
    width: 50%;
    text-align: center;
}

.count-total {
    width: 50%;
}

.website-info {
    width: 100%;
    height: auto;
    border: 0.3px solid rgb(207, 199, 199);
}

.head-info {
    padding-left: 10px;
    text-align: center;
    padding-top: 3vh;
}

.link-show-info {
    font-size: 14px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.btn-classification {
    padding-top: 1vh;
    padding-bottom: 1vh;
    display: flex;
    justify-content: flex-end;
}

.btn-classification .hrv-btn-group--item .hrv-btn.hrv-btn-primary {
    background: #2979FF;
}

.grant-type {
    padding-top: 1vh;
}

.render-history {
    padding-top: 1.5vh;
}

.history {
    border: 0.3px solid rgb(207, 199, 199);
}

.lb-title {
    padding-left: 1.5vw;
    margin-top: 2vh;
    font-size: 0.9rem;
}

.body-history {
    padding-left: 10px;
    padding-right: 10px;
}

.tag-below {
    display: flex;
    justify-content: space-between;
}

.lb-tag {
    margin-left: 10px;
}

.hrv-table thead th {
    color: #201f1f;
    font-size: 14px;
}

.hrv-table thead th,
.hrv-table tbody td,
.hrv-table tfoot td {
    padding: 14px 10px;
}

.transaction .page-section-wrapper {
    background-color: #fbfdff;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1vh;
    padding-top: 1vh;
}

.transaction .page-section-wrapper .page-section {
    background-color: #fbfdff;
}

.transaction .page-content {
    border: 0.3px solid #cfc7c7;
    margin-top: 1.5vh;
}

.transaction .section-title.text-break {
    padding-left: 1.5vw;
}

.transaction .anotated-header {
    font-size: .9rem;
    font-weight: 700;
    line-height: 2.4rem;
    margin-bottom: 0.2rem;
    margin-top: 0;
}

.transaction .anotated-description {
    color: #6c757d;
    font-size: .7rem;
    padding: 2% 0;
    white-space: normal;
}

.transaction .card {
    border: none;
    padding: 0%;
}

.label-userid {
    border: 0.3px solid rgb(207, 199, 199);
    border-radius: 5px;
    padding: 5px;
}

.btn-classification .hrv-btn-group--item {
    padding-left: 10px;
}

.input-transaction-user .hrv-select-selection {
    background-color: #fbfdff;
}

.lb-not-found {
    opacity: 0.5;
    margin-left: 10px;
}

.lb-attribute {
    font-size: 0.9rem;
    color: #333e;
    font-weight: 600;
}

.value-attribute {
    margin-bottom: 7px;
}