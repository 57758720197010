.carddetail-header {
  font-size: 1rem;
  color: #333;
  font-weight: 600;
}

.carddetail-title {
  font-size: 0.9rem;
  color: #333e;
  font-weight: 600;
  margin: 5px 0;
}

.carddetail-text {
  font-size: 0.9rem;
  color: #333d;
  font-weight: 500;
}

.row>* {
  padding: calc(var(--bs-gutter-x) * 0.5);
}

.infor-detail {
  display: flex;
  height: auto;
}

.name-detail {
  width: 30%;
  border: 0.3px solid rgb(207, 199, 199);
}

.value-detail {
  width: 70%;
  border: 0.3px solid rgb(207, 199, 199);
}

.text-child {
  padding: 12px;
}

.info-detail {
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
}

.info-detail-info {
  display: flex;
  padding-left: 20px;
  padding-top: 10px;
}

.lb-name {
  width: 50%;
  font-size: smaller;
}

.lb-value {
  word-wrap: break-word;
  width: 50%;
  font-size: smaller;
}

.lb-name-info {
  width: 35%;
  font-size: smaller;
}

.lb-value-info {
  word-wrap: break-word;
  width: 65%;
  font-size: smaller;
}
.info-shopplan{
  display: flex;
}
.lb-name-shopplan{
  width: 22%;
  font-size: small;
  margin-top: 5px;
  margin-left: 1vw;
}
.lb-value-shopplan{
  margin-left: 10px;
  /* width: 60%; */
}
.lb-value-shopplan:has(.select-product){
  padding-bottom: 10px;
  width: 71%;
}
.select-product{
  width: 100%;
}

.phone-verified{
  display: flex;
}
.icon-phone-verified{
margin-top: 10px;
}
.panel-default .hrv-btn.hrv-btn-size-small {
  min-width: 25px;
  min-height: 25px;
  margin-left: 0px !important;
  margin-top: 5px;
  padding: 0;
}