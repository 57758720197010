.layout-wapper{
    max-height:100vh;
    width: 100vw;
    overflow: hidden;
}

.layout-header {
    position: relative;
}

.layout-content {
    display: flex;
    min-height: 100vh;
    align-items: stretch;
}

.layout-container {
  flex: 1;
  padding: 1%;
  max-height: calc(100vh - 50px);
  margin: 0;
  overflow: auto;
  display: flex;
  align-items: center;
}

.layout-container > * {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout-container .hrv-breadcrumb {
  margin-bottom: var(--hrv_distance_md);
  width: 100%;
}


.list-metadatas .hrv-table tbody td,
.list-metadatas .hrv-table tfoot td {
    padding: 6px 10px;
    white-space: normal !important;
    word-break: normal !important;
    word-wrap: break-word !important;
}