.app-detail>* {
  width: 100%;
  background: var(--hrv-back-color-active);
}

/* .app-detail > * > * {
  padding: calc(0.5rem + 0.5vw);
  background: transparent;
  font-size: calc(11px + 0.3vw); 
} */
/* .app-detail > * > * span {
  width: fit-content;
  height: fit-content;
  vertical-align: bottom;
  line-height: calc(10px + 0.4vw);
} */
/* .app-detail > * > * > * + * {
  margin-top: calc(0.3rem + 0.3vw);
} */

.app-info {
  padding: 1% 2% 1.5% 2%;
  background: var(--hrv-translucent-black);
  border-radius: 0.5rem;
  color: white;
  max-width: 70%;
  max-height: 100%;
  overflow: hidden;
}

.app-info * {
  background: transparent;
  font-size: calc(9px + 0.9vw);
}

.app-status {
  width: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 10px
}

.app-status .status-selector {
  position: absolute;
  top: calc(-0.3rem + -0.4vw);
  right: calc(0rem + 0vw);
}

.app-rejection-reason {
  padding-top: 10px;
}

.app-html {
  padding: 2%;
  background: var(--hrv-back-color-active);
  max-height: 80vh;
  overflow: auto;
  font-family: initial;
}

.logo-banner-labels {
  display: flex;
}

.logo-banner-labels>* {
  flex: 1;
}

.logo-banner-labels>*+* {
  margin-left: calc(0.4rem + 0.4vw);
}

.app-logo-banner {
  display: flex;
  align-items: stretch;
  margin-top: calc(0.3rem + 0.3vw);
}

.app-logo-banner>* {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(0.4rem + 0.4vw);
  border: 1px solid var(--hrv-back-color-edge-light);
  border-radius: 0.3rem;
  background: #f0f5fa;
}

.app-logo-banner>*+* {
  margin-left: calc(0.4rem + 0.4vw);
}

.app-logo-banner>* * {
  max-height: 100%;
  max-width: 100%;
}

.app-client-scope code {
  padding: 4px 6px;
  background: #edc;
  border-radius: 0.3rem;
}

.app-client-scope code+code {
  margin-left: calc(0.5rem + 0.3vw);
}

.app-type-trial {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-detail {
  width: 80vw;
}

.input-label-top {
  margin-top: 10px;
  display: flex;
}

.image-area {
  text-align: center;
  background: #f3f4f4;
  border: 1px dashed #c6c3c4;
  border-radius: 5px;
}

.image-border {
  border: 1.5px groove #c6c3c4;
}

.input-body {
  opacity: 0.95;
}

.input-body-url {
  opacity: 0.8;
}

.border-photo {
  padding: 2%;
}

.img {
  border: 0.3px solid rgb(207, 199, 199);
}

.hrv-fileupload--content {
  padding-top: 1%;
}

.price-trial {
  display: flex;
  justify-content: space-between;
}

.price-trial-stop {
  justify-content: inherit
}

.price-trial-stop .approve-trial-day {
  width: 160px;
  display: flex;
}

.price-trial-stop .approve-trial-day label {
  margin-top: 9px;
  margin-left: 5px;
}

.price-trial-stop .price-type {
  width: 45%;
}

.price-type {
  text-align: left;
  width: 20%;
  margin-top: 3px;
}

.label-trial-day {
  display: flex;
  margin-left: auto;
  margin-right: 15px;
  margin-top: auto;
}

.label-trial-day input {
  width: 50px;
}

.input-trial {
  margin-top: -8px;
  margin-left: 5px;
  margin-right: 1px;
}

.approve-trial-day {
  text-align: right;
  width: 29%;
}

.horizontal-line {
  border-top: 1px solid rgb(218, 212, 212);
  width: 100% !important;
  height: 0 !important;
  padding: 0 !important;

}

.card-line {
  margin: calc(0.5rem + 0.5vw) 0;
}

.lb-pricing-details {
  display: flex;
}

.input-trial-day {
  margin-top: -7px;
  margin-left: 5px;
  margin-right: 5px;
}

.pricing-plan-free .label-trial-day {
  margin-top: 9px;
}

.history-plan-name,
.history-date-create,
.history-suggested-price,
.history-approved-price,
.history-reasons-for-canceling {
  width: 16%;
}

.history-plan-name {
  text-align: left;
}

.history-date-create,
.history-reasons-for-canceling {
  text-align: center;
}

.history-suggested-price,
.history-approved-price {
  text-align: right;
}

.history-status {
  width: 20%;
  text-align: center;
}

.pricing-price {
  margin-left: 5px;
  margin-top: 0.5px;
}

.status-history {
  display: flex;
  justify-content: center;
}

.app-detail .input-body-url {
  margin-top: 1vh;
}

.app-partner-info {
  padding: 12px 0;
  font-size: calc(10px + 0.3vw);
  display: flex;
  flex-direction: column;
}

.app-partner-info a {
  border: 1px solid #E0E0E0;
  border-radius: 0.3rem;
  padding: calc(0.5vw);
  overflow-wrap: break-word;
  background: #FAFAFA;
}

.hrv-alert-warning {
  background-color: rgba(253, 190, 65, 0.1) !important;
}

.notify-request {
  position: absolute;
  right: -18px;
  top: -4px
}

.hrv-tooltip ul {
  padding: 0 10px;
  line-height: 1.5;
  margin: 0;
}

.hrv-tooltip {
  opacity: 85%;
}

.hrv-switch-custom-app
{
  background: none;
  text-align: right;
}
.hrv-switch-custom-app .hrv-switch
{
  width: 100px;
  background: #909eaba1;
}
.hrv-switch-checked {
  background-color: #22C993!important;
}