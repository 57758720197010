.management-screen {
    display: flex;
    width: 99%;
}

.col-md-6 {

    padding: 5px;
    width: 50%
}

.full-screen {
    width: 100%;
}

.panel-default {
    border-radius: 5px;
    border: solid 1px #dee5eb;
    margin-bottom: 3vh;
}

.panel-heading {
    padding: 10px 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #fafcfc;
    border-bottom: solid 1px #e9eff3;
    color: #505357;
    margin-bottom: 10px;
}

.management-screen .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    opacity: 0.7;
    color: inherit
}

.scroll-visible {
    display: flex;
    padding: 23px;
}

.text-left {
    margin-left: 1vw;
}

.input-name-group {
    /* height: 4.5vh;
    min-height: 4vh;*/
    width: 28vw;
    height: 28px;
    font-size: 13px;
    line-height: 20px;
    padding-bottom: 0;
    padding-top: 0;
}

.border-right {
    margin-top: 3px;
    font-weight: 600;
    font-size: 0.9rem;
}

.form-group {
    margin-bottom: 10px;
}

.management-screen .hrv-btn {
    margin-left: 10px;
}

.management-screen .hrv-table-container {
    border: none;
}

.management-screen .listview-content td {
    font-size: 0.8rem;
}

.btn-delete {
    width: 10px;
    height: 10px;
}

.management-screen .hrv-btn.hrv-btn-link {
    color: red;
}

.tb-list-user {
    height: 3vh;
}

.layout-container .hrv-btn.hrv-btn-primary {
    margin-left: 10px;
    background: none;
    background-color: #246deb;
}

.layout-container .hrv-btn.hrv-btn-primary:hover {
    background-color: #2979ff;
}


.modal-dialog .hrv-btn.hrv-btn-primary {
    margin-left: 6px;
    background: none;
    background-color: #246deb;
}

.modal-dialog .hrv-btn.hrv-btn-primary:hover,
.layout-container .hrv-btn.hrv-btn-primary:hover {
    background-color: #2979ff;
}

.modal-dialog .hrv-btn.hrv-btn-danger,
.layout-container .hrv-btn.hrv-btn-danger {
    background: none;
    background-color: #e64242;
}

.modal-dialog .hrv-btn.hrv-btn-danger:hover,
.layout-container .hrv-btn.hrv-btn-danger:hover {
    background-color: #f95b5b;
}

.i_role_system {
    position: absolute;
    right: 15px;
    top: 5px;
}

.modalUpdateRole .modal-dialog {
    max-width: 600px;
}

.modalUpdateRole .modal-title {
    line-height: 1;
}

.modalUpdateRole .hrv-btn-link {
    min-height: 15px;
    padding: 0;
}

.modal-title h6 {
    line-height: 2;
    font-size: 14px;
}