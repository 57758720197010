.card-info>* {
  background: transparent;
  padding: calc(0.4rem + 0.4vw);
  font-size: calc(11px + 0.3vw);
  display: flex;
  flex-direction: column;
}

.card-info>* span {
  height: fit-content;
  vertical-align: bottom;
  color: #123a;
}

.card-info>*>span+*:not(span) {
  margin-top: calc(0.3rem + 0.3vw);
  border: 1px solid #abcd;
  border-radius: 0.3rem;
  padding: calc(0.1rem + 0.2vw);
  overflow-wrap: break-word;
}

.card-info>*>span+span {
  padding: calc(0.3rem + 0.3vw);
  color: #456;
}