.theme-list-wrapper {
  width: 100%;
}

.theme-name {
  min-width: 13rem;
}

.theme-partner {
  min-width: 13rem;
}

.theme-cost {
  min-width: 7rem;
}

.theme-demo {
  min-width: 10rem;
}

.theme-date {
  min-width: 10rem;
}

.theme-download {
  text-align: center;
}
.theme-list-wrapper .data-list-header-cell *{
  display: initial;
}
.theme-list-wrapper .data-list-header-cell.object.theme-download{
  text-align: center;

}
.theme-list-wrapper .data-list-header-cell.object, .data-list-header-cell.string{
  text-align: left;
}
.hrv-table .data-list-header-cell.theme-name,
.hrv-table .data-list-cell.theme-name {
  text-align: left;
}

.theme-status {
  text-align: center !important;
}

.data-list-sort-label {
  text-align: center;
}

.theme-list-wrapper .status-wrapper .hrv-badges {
  padding: 1px 4px 1px !important;
  margin: 2px
}

.theme-list-wrapper .hrv-badges-body {
  font-size: 9px !important;
}
