.listview-title {
  color: #637381;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  padding: 1.5rem 2rem;
}

.listview-empty {
  background-repeat: no-repeat;
}

.listview-empty img {
  max-width: 20vw;
  max-height: 20vw;
}

.ui-section-empty-icon {
  flex: 1;
}

.listview {
  width: 100%;
  overflow-x: auto;
  border-radius: 0.5rem;
}

.listview-content {
  min-width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;

}

.listview-searchbox {
  margin-bottom: 1rem;
}

.listview-searchbox .hrv-svg-inline-block {
  width: 23px;
  height: 23px;
}

.listview-searchbox .hrv-next-input--stylized .hrv-next-input.hrv-next-input--invisible {
  padding-left: 0.5rem;
}

.listview-searchbox input:hover,
.listview-searchbox input:focus {
  border: none;
  outline: none;
}

.listview-header {
  display: flex;
  border-bottom: 0.2rem solid var(--hrv-border-light);
  color: var(--hrv_black_blue);
  font-weight: 450;
  padding: 0 0.5rem 1rem 0.5rem;
  min-width: max-content;
}

.listview-header-cell {
  padding: 0.5rem 1rem;
}

.listview-row {
  font-size: 0.9rem;
  border-bottom: 0.1rem solid var(--hrv-border-light);
  padding: var(--hrv_distance_xs) var(--hrv_distance_sm);
  display: flex;
  align-items: center;
  min-width: max-content;
}

.listview-row:hover {
  background: #f5faff;
  box-shadow: 1px 1px 1px 1px #e0e5fe;
  border-radius: 0.5rem;
}

.listview-row>*,
.listview-cell {
  padding: 0.5rem 1rem;
}

.listview-footer {
  min-width: 30vw;
  position: sticky;
  position: -webkit-sticky;
}

.listview-footer .hrv-btn.hrv-btn-default {
  color: var(--hrv-darkest-blue-trans);
}

.listview-footer .hrv-btn.hrv-btn-default[disabled],
.listview-footer .hrv-btn.hrv-btn-default[disabled]:active {
  color: var(--hrv-disabled-white);
}

.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--hrv_distance_md);
  padding-bottom: var(--hrv_distance_sm);
  color: var(--hrv-darkest-blue)
}

.footer-left {
  position: sticky;
  left: 0.5rem;
  display: flex;
  align-items: center;
  width: 55%;
  justify-content: space-between;
  max-width: 58vw;
  min-width: 14rem;
}

.footer-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  position: sticky;
  left: 5rem;
}

.footer-wrapper .hrv-next-input,
.footer-wrapper .hrv-input-number {
  width: 5rem;
  height: fit-content;
  padding: 0.5rem;
  margin-right: 1rem;
}

.footer-wrapper .hrv-select {
  width: 6rem;
}

.footer-wrapper .hrv-select-selection__rendered {
  margin: 0;
  min-width: fit-content;
  padding: var(--hrv_distance_xs) var(--hrv_distance_sm);
}

.footer-wrapper .hrv-select-arrow {
  right: 9px;
}

.footer-wrapper .hrv-btn.hrv-btn-default[disabled],
.footer-wrapper .hrv-btn.hrv-btn-default:hover:active,
.footer-wrapper button:disabled:hover {
  border: none;
}

.footer-wrapper button:disabled:hover {
  cursor: default;
}

.footer-wrapper .hrv-btn+.hrv-btn {
  margin-left: 1rem;
}

.footer-wrapper .hrv-btn:hover {
  border-color: var(--hrv_light_white_blue);
}

.footer-wrapper .border-ellipse {
  padding: 0;
  color: inherit;
  border: none;
  background: none !important;
  min-width: unset;
}


@media screen and (min-width: 240px) {
  .listview-footer {
    padding: 0.3rem;
  }

  .footer-middle {
    left: 10rem;
  }
}

@media screen and (min-width: 320px) {
  .listview-footer {
    padding: 0.4rem;
  }

  .footer-middle {
    left: 13rem;
  }
}

@media screen and (min-width: 480px) {
  .listview-footer {
    padding: 0.5rem;
  }

  .footer-middle {
    left: 15rem;
  }
}

@media screen and (min-width: 768px) {
  .listview-footer {
    padding: 0.6rem;
  }

  .footer-middle {
    left: 18rem;
  }
}

@media screen and (min-width: 1024px) {
  .listview-footer {
    padding: 0;
  }

  .footer-middle {
    left: 22rem;
  }
}

@media screen and (min-width: 1500px) {
  .listview-footer {
    padding: 0;
  }

  .footer-middle {
    left: 25rem;
  }
}

.listview .hrv-next-input--stylized {
  padding: 0 0.5rem;
}

.no-gutters.ui-stack-item.ui-stack-item-fill {
  margin: 2px;
}