.app-price-detail {
  border: 1px solid var(--hrv-back-color-edge-light);
  border-radius: 0.3rem;
  padding: 2%;
}
.app-price-detail + .app-price-detail {
  margin-top: 2%;
}

.app-price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rejection-reason {
  white-space: nowrap;
  margin-right: 0.5rem;
}