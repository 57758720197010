.data-filter-wrapper {
  margin-left: var(--hrv_distance_sm);
}

.data-filter-wrapper.has-value {
  opacity: 1 !important;
  color: var(--hrv-light-blue);
}

.data-filter-content {
  width: calc(18rem + 1vw);
}

.hrv-daterangepicker .hrv-daterangepicker--icon,
.hrv-daterangepicker--calendar {
  padding-left: 0;
}