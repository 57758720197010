.website-info-wrpayouter {
    padding: 0.5rem;
}
.partner-payout-partner-name {
    width: 220px;
    /* width: 20%; */
}
.partner-payout-ref {
    width: 7rem;
    /* width: 10%; */
}
.partner-payout-transaction-type {
    width: 9rem;
    /* width: 15%; */
}
.partner-payout-shop-name {
    width: 13rem;
    /* width: 15%; */
}
.partner-payout-price-cost {
    width: 150px;
    /* width: 15%; */
}
.partner-payout-created-date {
    width: 13rem;
    /* width: 12.5%; */
}
.partner-payout-payment-date {
    width: 13rem;
    /* width: 12.5%; */
}
.partner-payout-status {
    width: 12rem;
    text-align: center;
    /* width: 10%; */
}
.partner-payout-transaction-id {
    width: 10rem;
    /* width: 10%; */
}
.partner-payout-transaction-status {
    text-align: center;
    margin: auto;
}