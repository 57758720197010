.popup-outside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: #0121;
}
.popup-outside.dim {
  background: var(--hrv-back-color-inactive);
}

.popup-wrapper {
  background: unset;
  padding: 0.15rem;
  border-radius: 0.5rem;
  max-width: 80vw;
  max-height: 85vh;
  overflow: auto;
}

.popup {
  min-width: 20vw;
  max-width: 75vw;
  background: var(--hrv-back-color-active);
  color: var(--hrv-fore-color);
  border-radius: 0.3rem;
}

.popup-header {
  outline:none;
  text-align: center;
  font-size: large;
  font-weight: bold;
  background: linear-gradient(to bottom, var(--hrv-dark-blue), var(--hrv-darker-blue));
  color: var(--hrv-back-color-active);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding: 0.7rem;
  user-select: none;
}

.popup-body {
  padding: calc(0.7rem + 0.5vw);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; 
}

.popup-footer {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.popup-footer .hrv-btn {
  font-weight: bold;
}
